import React, { useEffect, useRef, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
// import moment from "moment"
import _, { map } from "lodash"

import logo from "../../../assets/images/logo/LOHA.png"
import isoBadge from "../../../assets/images/iso-badge.png"
import { createWorkOrder, getWorkOrderChoices, getWorkOrderDetail, updateWorkOrder } from "store/actions"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { getDealers } from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const Create = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const Role = localStorage.getItem("role")
  const { orderDetail, loading, orderitemLoading, dealers, workOrderChoices } = useSelector(
    state => ({
      orderDetail: state.WorkOrders.workOrderDetail,
      orderitem: state.Orders.orderDetail.orderitem,
      loading: state.Orders.loading,
      orderitemLoading: state.Orders.orderitemLoading,
      dealers: state?.Dealers?.dealers,
      workOrderChoices: state?.WorkOrders?.workOrderChoices,
    })
  )

  // const { orderDetail, loading, orderitemLoading, dealers, workOrderChoices } = useSelector(
  //   state => ({
  //     orderDetail: state.Orders.orderDetail,
  //     orderitem: state.Orders.orderDetail.orderitem,
  //     loading: state.Orders.loading,
  //     orderitemLoading: state.Orders.orderitemLoading,
  //     dealers: state?.Dealers?.dealers,
  //     workOrderChoices: state?.WorkOrders?.workOrderChoices,
  //   })
  // )


  const [isPrintView, setIsPrintView] = useState(false)
  const [selectedDealer, setSelectedDealer] = useState({ "label": "---", "value": "10a4ea9c-4c98-4708-a704-1bb1f1891089" })
  const [selectedImages, setSelectedImages] = useState([])
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [columnsCount, setColumnsCount] = useState(0)



  // const useDeepEffect = (callback, dependencies) => {
  //   const previousDependencies = useRef();

  //   useEffect(() => {
  //     if (!_.isEqual(previousDependencies.current, dependencies)) {
  //       callback();
  //     }
  //     previousDependencies.current = dependencies;
  //   }, [dependencies, callback]);
  // };


  const download = () => {
    setIsPrintView(true)
    setTimeout(() => {
      window.print(); // Step 2: Print after UI updates
    }, 100);
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  // function userExists(arr, name) {
  //   const isExist = arr.some(function (el) {
  //     return el.addon_name === name
  //   })
  //   return { isExist: isExist, data: arr.filter(i => i.addon_name === name)[0] }
  // }

  // const handleOrderItemAddon = arr => {
  //   const modifiedArr = arr?.filter(
  //     i => i?.addon_name !== "Manchary" && i?.addon_name !== "Panel"
  //   )
  //   return modifiedArr
  // }

  // const handleAddonTd = item => {
  //   if (item?.addon_name === "Manchary") {
  //     return <td>9</td>
  //   }
  // }
  const handleRawImages = async (rawImages) => {
    const promises = rawImages.map(async (file) => {
      try {
        const response = await fetch(file.image); // Fetch the image from the URL
        const blob = await response.blob(); // Get the image as a Blob
        const base64Image = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // Convert Blob to Base64 string
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        return {
          ...file,
          image: base64Image // Replace image URL with Base64 string
        };
      } catch (error) {
        console.error('Error converting file to base64:', error);
        return file; // Return the original file object in case of an error
      }
    });


    try {
      const base64Files = await Promise.all(promises);

      console.log("Base64 Files:", base64Files);

      setSelectedImages((prevImages) => {
        // Map new files to the desired object structure
        const newImages = base64Files

        // Combine previous images and new images
        const combinedImages = [...prevImages, ...newImages];

        // Deduplicate based on the `image` property
        const uniqueImages = combinedImages.reduce((acc, current) => {
          const exists = acc.some((img) => img.image === current.image);
          return exists ? acc : [...acc, current];
        }, []);

        return uniqueImages;
      });
    } catch (error) {
      console.error("Error processing images:", error);
    }

  };




  // useEffect(() => {
  //   console.log("orderDetail ...");
  //   console.log(orderDetail);
  //   console.log(params?.id);
  // }, [orderDetail])


  useEffect(() => {
    setValue('work_order_images', selectedImages);
  }, [selectedImages])





  // Update total quantity whenever workOrderItems changes
  // useEffect(() => {
  //   setValue("total_quantity", totalQuantity);
  // }, [totalQuantity, setValue]);




  // const handleChangeQty = (e, id) => {
  //   setItemQty({
  //     ...itemQty,
  //     qty: e.target.value,
  //     itemId: id,
  //   })
  // }

  // const onSubmitQty = e => {
  //   e.preventDefault()
  //   dispatch(updateOrderItem("", itemQty?.itemId, itemQty?.qty))
  //   setShowEditInput("")
  // }

  // const handleShowInput = (id, qty) => {
  //   setItemQty({
  //     ...itemQty,
  //     qty: qty,
  //     itemId: id,
  //   })
  //   setShowEditInput(id)
  // }



  function handleEnters(q) {
    dispatch(getDealers(q, 1))

  }
  function handleDealerSelect(event) {
    setSelectedDealer({ "label": event.label, "value": event.value })
    setValue('mobile', event.mobile);
  }
  const dealerOptionGroup = [
    {
      options: dealers?.results?.map((result, index) => ({
        key: index,
        label: `${result?.account.username}`,
        value: result?.id,
        mobile: result?.account?.phone
      })),
    },
  ]



  const handleImages = (event) => {
    const files = Array.from(event.target.files);
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Converts file to base64 string
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    });

    Promise.all(promises)
      .then(base64Files => {
        console.log("base64Files");
        console.log(base64Files);

        setSelectedImages((prevImages) => [...prevImages, { image: base64Files[0] }]);
      })
      .catch((error) => {
        console.error('Error converting files to base64:', error);
      });
  };


  // function handleImages(event) {
  //   console.log(selectedImages);
  //   setSelectedImages((prevImages) => [...prevImages, event.target.files[0]]);
  //   console.log(event);
  //   console.log(selectedImages);
  //   // setRawData({
  //   //   ...rawData,
  //   //   orderitem: {
  //   //     ["product"]: event.value,
  //   //     ["productName"]: event.label,
  //   //   },
  //   // })
  //   // dispatch(getAddons(event?.value))
  // }

  const handleRemoveImage = (index) => {
    // Remove the image by filtering out the selected index
    setSelectedImages(selectedImages.filter((_, i) => i !== index));
    console.log(index);
  };


  const { control, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      title: 'test title',
      dealer: selectedDealer?.value,
      order_date: getCurrentDate(),
      customer_name: '',
      delivery_date: getCurrentDate(),
      delivery_site: '',
      billing_address: '',
      gst_number: '',
      total_cost: 0,
      order_status: 'Pending',
      notes: '',
      column1_title: "column 1",
      column2_title: "column 2",
      column3_title: "column 3",
      work_order_items: params?.id ? "" : [{
        channel_size: '---',
        channel_guage: '---',
        particulars: '',
        type: '---',
        width: '',
        height: '',
        grill_type: '---',
        grill_shape: '---',
        manchary: false,
        louvers: '',
        panel: '---',
        design: '---',
        divider: '',
        open_side: '',
        doorsill: false,
        quantity: 0,
        hinges: '---',
        stopper: 0,
        gate_hook: 0,
        lock: 0,
        item_cost: 0,
        item_price: 0,
        check_design: "",
        arch_design: "",
        glass_partition: "",
        column1: "",
        column2: "",
        column3: "",
      }],

      // Boolean fields outside work_order_items
      has_channel_size: true,
      has_channel_guage: true,
      has_particulars: true,
      has_type: true,
      has_width: true,
      has_height: true,
      has_grill_type: true,
      has_grill_shape: true,
      has_manchary: true,
      has_louvers: true,
      has_panel: true,
      has_design: true,
      has_divider: true,
      has_open_side: true,
      has_doorsill: true,
      has_quantity: true,
      has_hinges: true,
      has_stopper: true,
      has_gate_hook: true,
      has_lock: true,
      has_item_cost: true,
      has_item_price: true,
      has_check_design: true,
      has_arch_design: true,
      has_glass_partition: true,
      has_column1: true,
      has_column2: true,
      has_column3: true,

    }
  });

  useEffect(() => {
    dispatch(getDealers("", 1))
    dispatch(getWorkOrderChoices("", 1))
    if (params?.id) {
      dispatch(getWorkOrderDetail(params?.id));
    }
  }, [])


  useEffect(() => {
    if (params?.id) {
      setSelectedDealer({ "label": orderDetail?.dealer_name, "value": orderDetail?.dealer })
      setValue('mobile', orderDetail?.mobile);
      setValue('order_date', orderDetail?.order_date);
      setValue('customer_name', orderDetail?.customer_name);
      setValue('delivery_date', orderDetail?.delivery_date);
      setValue('delivery_site', orderDetail?.delivery_site);
      setValue('billing_address', orderDetail?.billing_address);
      setValue('gst_number', orderDetail?.gst_number);
      setValue('total_cost', orderDetail?.total_cost);
      setValue('work_order_items', orderDetail?.work_order_items);
      setValue('work_order_images', orderDetail?.work_order_images);
      setValue('order_status', orderDetail?.order_status);
      setValue('notes', orderDetail?.notes);

      setValue('column1_title', orderDetail?.column1_title);
      setValue('column2_title', orderDetail?.column2_title);
      setValue('column3_title', orderDetail?.column3_title);

      setValue('has_channel_size', orderDetail?.has_channel_size);
      setValue('has_channel_guage', orderDetail?.has_channel_guage);
      setValue('has_particulars', orderDetail?.has_particulars);
      setValue('has_type', orderDetail?.has_type);
      setValue('has_width', orderDetail?.has_width);
      setValue('has_height', orderDetail?.has_height);
      setValue('has_grill_type', orderDetail?.has_grill_type);
      setValue('has_grill_shape', orderDetail?.has_grill_shape);
      setValue('has_manchary', orderDetail?.has_manchary);
      setValue('has_louvers', orderDetail?.has_louvers);
      setValue('has_panel', orderDetail?.has_panel);
      setValue('has_design', orderDetail?.has_design);
      setValue('has_divider', orderDetail?.has_divider);
      setValue('has_open_side', orderDetail?.has_open_side);
      setValue('has_doorsill', orderDetail?.has_doorsill);
      setValue('has_quantity', orderDetail?.has_quantity);
      setValue('has_hinges', orderDetail?.has_hinges);
      setValue('has_stopper', orderDetail?.has_stopper);
      setValue('has_gate_hook', orderDetail?.has_gate_hook);
      setValue('has_lock', orderDetail?.has_lock);
      setValue('has_item_cost', orderDetail?.has_item_cost);
      setValue('has_item_price', orderDetail?.has_item_price);
      setValue('has_check_design', orderDetail?.has_check_design);
      setValue('has_arch_design', orderDetail?.has_arch_design);
      setValue('has_glass_partition', orderDetail?.has_glass_partition);

      setValue('has_column1', orderDetail?.has_column1);
      setValue('has_column2', orderDetail?.has_column2);
      setValue('has_column3', orderDetail?.has_column3);

      // setSelectedImages(orderDetail?.work_order_images ? orderDetail.work_order_images : []);
      // console.log("orderDetail");
      // console.log(orderDetail);
      // console.log(orderDetail);
      // console.log(orderDetail);

      // convertImagesToBase64();
      // setSelectedImages()
      handleRawImages(orderDetail?.work_order_images ? orderDetail.work_order_images : [])
    }else{
      console.log("no params.id");
      
    }

  }, [orderDetail])




  // const workOrderItems = watch("work_order_items");

  // // Calculate total quantity whenever work_order_items change
  // useEffect(() => {
  //   const totalQty = workOrderItems.reduce((sum, item) => sum + (item.quantity || 0), 0);
  //   SetTotalQuantity(totalQty);
  //   console.log(totalQty);
  //   console.log(totalQuantity);

  // }, [workOrderItems, SetTotalQuantity]);


  const calculateTotalQuantity = () => {
    const quantities = watch("work_order_items")?.map(item => item.quantity);
    const totalQty = (quantities || []).reduce((sum, qty) => sum + (Number(qty) || 0), 0);
    setTotalQuantity(totalQty);
  }


  const workOrderItems = watch("work_order_items");

  useEffect(() => {
    if (workOrderItems) {
      const totalQty = workOrderItems.reduce((sum, item) => sum + (Number(item.quantity) || 0), 0);
      setTotalQuantity(totalQty);
    }
  }, [workOrderItems]);

  useEffect(() => {
    calculateTotalQuantity
  }, [watch("work_order_items")]); // Re-run when any quantity changes

  const getColumnCount = () => {
    const allThCount = document.querySelectorAll(".order_item_table.table th").length;
    const disabledThCount = document.querySelectorAll(".order_print_view .order_item_table.table th.disabled_column").length;
    setColumnsCount(allThCount - disabledThCount)
  };
  useEffect(() => {
    getColumnCount()
  }, [isPrintView, columnsCount])



  const { fields, append, remove } = useFieldArray({
    control,
    name: 'work_order_items', // Name must match the array field in useForm
  });

  const onSubmit = (data) => {
    data['dealer'] = selectedDealer?.value;
    if (params?.id) {
      dispatch(updateWorkOrder(data, params.id, history))
    } else {
      dispatch(createWorkOrder(data, history))

    }
  };

  // selectedImages.map((image, index) => {
  //   console.log("image");
  //   console.log(image);
  // })


  const handleToggle = () => {
    setIsPrintView(!isPrintView); // Toggle the state
  };



  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <div className={`page-content print_bg_white ${isPrintView ? "order_print_view" : ""}`}>
          <div className="d-flex align-items-center justify-content-end pt-5 print_hide">

            <button
              onClick={handleToggle}
              className={`px-4 py-2 me-2 rounded-lg text-white btn btn-${isPrintView ? "success" : "secondary"
                }`}
            >
              {isPrintView ? "Print View : ON" : "Print View : OFF"}
            </button>
            <div
              type="button"
              className="btn btn-outline-info d-flex display-none"
              style={{
                alignItems: "center",
                width: "fit-content",
                border: "1px solid #cccc",
              }}
              onClick={download}
            >
              PDF
              <i className="mdi mdi-download d-block font-size-16 mx-1"></i>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-4 px-2">
            <div className="w-33"><img
              src={isoBadge}
              alt="image"
              className="iso_Badge"
            /></div>
            <div
              className="d-flex align-items-center w-33"
              style={{ flexDirection: "column" }}
            >
              <img
                src={logo}
                alt="image"
                className="loha_logo"
              />
              <p className="pt-1 mb-0 pb-0">Vengara, Malappuram</p>
              {/* <p className="m-0 p-0">DT. Kerala(32)-676304</p> */}
              <a href="tel:+919288022780">PH: +91 9288 022 780</a>
              <a href="mailto:lohasteelsales@gmail.com">
                Lohasteelsales@gmail.com
              </a>
            </div>
            <div className="w-33 d-flex align-items-center justify-content-end order-number">
              <div className="w-fit">
                <p className="d-flex order_border mb-0 "> Order No : </p>
                <input placeholder="Order No" value={orderDetail?.auto_id} />
              </div>
            </div>
          </div>


          <AvForm className="workorder-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between align-items-stretch">
              <div className="table-responsive left-box">
                <Table className="table address_table table-bordered mb-0 w-100">
                  <tbody>
                    <tr>
                      <th>DEALER</th>
                      <td>
                        {isPrintView ? (
                          `${selectedDealer["label"]}`
                        ) : (
                          <Select
                            onInputChange={handleEnters}
                            value={selectedDealer}
                            placeholder={selectedDealer}
                            onChange={handleDealerSelect}
                            options={dealerOptionGroup}
                            classNamePrefix="select2-selection"
                            isLoading={false}
                            required={"required"}
                            name={"dealer"}
                          />
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">MOB</th>
                      <td>
                        <input
                          {...register('mobile')}
                          placeholder="mobile"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">ORDER DATE</th>
                      <td>
                        {isPrintView ? (
                          `${watch("order_date")}`
                        ) : (
                          <input type="date"
                            {...register('order_date')}
                            placeholder="order_date"
                          />)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CUSTOMER NAME</th>
                      <td>
                        <input
                          {...register('customer_name')}
                          placeholder="customer_name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">DELIVERY DATE</th>
                      <td>
                        {isPrintView ? (
                          `${watch("delivery_date")}`
                        ) : (
                          <input type="date"
                            {...register('delivery_date')}
                            placeholder="delivery_date"
                          />)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">DELIVERY SITE</th>
                      <td>
                        <input
                          {...register('delivery_site')}
                          placeholder="delivery_site"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">BILLING ADDRESS</th>
                      <td>
                        <input
                          {...register('billing_address')}
                          placeholder="billing_address"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">GST NUMBER</th>
                      <td>
                        <input
                          {...register('gst_number')}
                          placeholder="gst_number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">STATUS</th>


                      {/* order_status */}
                      <td id="2" className="text-red">
                        {isPrintView ? (
                          `${watch("order_status")}`
                        ) : (
                          <Controller
                            name={`order_status`}
                            control={control}
                            // defaultValue={order_status || ''}
                            render={({ field }) => (
                              <select {...field}>
                                {workOrderChoices?.order_status?.map((value, idx) => (
                                  <option key={idx} value={value}>{value}</option>
                                ))}
                              </select>
                            )}
                          />
                        )}
                      </td>
                    </tr>

                  </tbody>
                </Table>

              </div>
              <div className="table-responsive w-100 align-items-stretch">
                <Table className="table table-bordered mb-0 w-100">
                  <thead>
                    <tr>
                      <th className="text-center p-0">Drawings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedImages.length > 0 &&
                          selectedImages.map((image, index) => (
                            <div className="image-container" key={index} style={{ margin: '10px' }}>
                              <img
                                src={image.image}
                                alt={`Selected file ${index + 1}`}
                                width="100"
                                onLoad={() => image} // Revoke object URL to release memory
                              />
                              <span className="print_hide"
                                onClick={() => handleRemoveImage(index)}
                                disabled
                              > X </span>
                            </div>
                          ))}
                      </td>
                    </tr>
                    <tr className="print_hide">
                      <td>
                        <AvField
                          className="work_order_image_input"
                          type={"file"}
                          name={"work_order_image"}
                          errorMessage="Invalid Input"
                          placeholder={"Drawings"}
                          onChange={e => handleImages(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>CUTTING</th>
                    <th>BENDING</th>
                    <th>PRODUCT</th>
                    <th>PAINTING</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: "89px" }}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </Table> */}
              </div>
            </div>

            <div className="row-box">
              <div className="d-flex overflow-x-scroll print_scroll_hide" id="res-tab">
                <Table
                  id="myTable"
                  className="table order_item_table table-bordered mb-0 text-center"
                >
                  <thead>
                    <tr className={`print_hide ${isPrintView ? "d-none" : ""}`}>
                      <td id="1"></td>
                      <td id="2">
                        <input type="checkbox" {...register('has_channel_size')} />
                      </td>
                      <td id="3">
                        <input type="checkbox" {...register('has_particulars')} />
                      </td>
                      <td id="4">
                        <input type="checkbox" {...register('has_width')} />
                      </td>
                      <td id="5">
                        <input type="checkbox" {...register('has_height')} />
                      </td>
                      <td id="6">
                        <input type="checkbox" {...register('has_hinges')} />
                      </td>
                      <td id="7">
                        <input type="checkbox" {...register('has_grill_type')} />
                      </td>
                      <td id="7">
                        <input type="checkbox" {...register('has_grill_shape')} />
                      </td>
                      <td id="8">
                        <input type="checkbox" {...register('has_divider')} />
                      </td>
                      <td id="9">
                        <input type="checkbox" {...register('has_panel')} />
                      </td>
                      <td id="10">
                        <input type="checkbox" {...register('has_design')} />
                      </td>
                      <td id="11">
                        <input type="checkbox" {...register('has_arch_design')} />
                      </td>
                      {/* <td id="12">
                        <input type="checkbox" {...register('has_check_design')} />
                      </td> */}
                      <td id="13">
                        <input type="checkbox" {...register('has_louvers')} />
                      </td>
                      <td id="14">
                        <input type="checkbox" {...register('has_glass_partition')} />
                      </td>
                      <td id="15">
                        <input type="checkbox" {...register('has_manchary')} />
                      </td>
                      <td id="16">
                        <input type="checkbox" {...register('has_open_side')} />
                      </td>
                      <td id="17">
                        <input type="checkbox" {...register('has_doorsill')} />
                      </td>
                      <td id="18">
                        <input type="checkbox" {...register('has_column1')} />
                      </td>
                      <td id="19">
                        <input type="checkbox" {...register('has_column2')} />
                      </td>
                      <td id="20">
                        <input type="checkbox" {...register('has_column3')} />
                      </td>
                      <td id="21">
                        <input type="checkbox" {...register('has_quantity')} />
                      </td>
                      <td id="22" className="print_hide"></td>
                    </tr>
                    <tr className="">
                      <th id="1">Sl No:</th>
                      <th id="2" className={`${!watch("has_channel_size") ? "disabled_column" : ""}`}>Channel</th>
                      <th id="3" className={`${!watch("has_particulars") ? "disabled_column" : ""}`}>Particulars</th>
                      <th id="4" className={`${!watch("has_width") ? "disabled_column" : ""}`}>Width</th>
                      <th id="5" className={`${!watch("has_height") ? "disabled_column" : ""}`}>Height</th>
                      <th id="6" className={`${!watch("has_hinges") ? "disabled_column" : ""}`}>Hinges</th>
                      <th id="7" className={`${!watch("has_grill_type") ? "disabled_column" : ""}`}>Grill Type</th>
                      <th id="7" className={`${!watch("has_grill_shape") ? "disabled_column" : ""}`}>Grill Shape</th>
                      <th id="8" className={`${!watch("has_divider") ? "disabled_column" : ""}`}>Divider</th>
                      <th id="9" className={`${!watch("has_panel") ? "disabled_column" : ""}`}>Panel</th>
                      <th id="10" className={`${!watch("has_design") ? "disabled_column" : ""}`}>Design</th>
                      <th id="11" className={`${!watch("has_arch_design") ? "disabled_column" : ""}`}>Arch</th>
                      {/* <th id="12" className={`${!watch("has_check_design") ? "disabled_column" : ""}`}>Check</th> */}
                      <th id="13" className={`${!watch("has_louvers") ? "disabled_column" : ""}`}>Louvers</th>
                      <th id="14" className={`${!watch("has_glass_partition") ? "disabled_column" : ""}`}>Glass Partition</th>
                      <th id="15" className={`${!watch("has_manchary") ? "disabled_column" : ""}`}>Manchary</th>
                      <th id="16" className={`${!watch("has_open_side") ? "disabled_column" : ""}`}>Open Side</th>
                      <th id="17" className={`${!watch("has_doorsill") ? "disabled_column" : ""}`}>Doorsill</th>

                      <th id="18" className={`${!watch("has_column1") ? "disabled_column" : ""}`}>
                        {isPrintView ? (
                          `${watch("column1_title")}`
                        ) : (
                          <input type="text"
                            {...register('column1_title')}
                            placeholder="column1_title"
                          />)}
                      </th>
                      <th id="19" className={`${!watch("has_column2") ? "disabled_column" : ""}`}>
                        {isPrintView ? (
                          `${watch("column2_title")}`
                        ) : (
                          <input type="text"
                            {...register('column2_title')}
                            placeholder="column2_title"
                          />)}
                      </th>
                      <th id="20" className={`${!watch("has_column3") ? "disabled_column" : ""}`}>
                        {isPrintView ? (
                          `${watch("column3_title")}`
                        ) : (
                          <input type="text"
                            {...register('column3_title')}
                            placeholder="column3_title"
                          />)}
                      </th>

                      <th id="21" className={`${!watch("has_quantity") ? "disabled_column" : ""}`}>Quantity</th>
                      <th id="22" className="print_hide"></th>
                    </tr>
                  </thead>
                  <tbody>


                    {map(fields, (field, index) => (
                      <tr className="workorderitem" id={field.id} key={index}>
                        {/* Sl No */}
                        <td id="1" className={`text-red`}>
                          {index + 1}
                        </td>

                        {/* Channel */}
                        <td id="2" className={`text-red ${!watch("has_channel_size") ? "disabled_column" : ""}`} title={field.channel_size}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].channel_size`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].channel_size`}
                              control={control}
                              defaultValue={field.channel_size || ''}
                              render={({ field }) => (
                                <select style={{ width: "70px" }} {...field}>
                                  {workOrderChoices?.channel_size?.map((value, idx) => (
                                    <option key={idx} value={value}>{value}</option>
                                  ))}
                                </select>
                              )}
                            />
                          )}
                        </td>

                        {/* Particulars */}
                        <td id="4" className={`text-red ${!watch("has_particulars") ? "disabled_column" : ""}`} title={field.particulars}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].particulars`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].particulars`}
                              control={control}
                              defaultValue={field.particulars}
                              render={({ field }) => <input style={{ width: "76px" }} {...field} />}
                            />
                          )}
                        </td>

                        {/* Width */}
                        <td id="5" className={`text-red ${!watch("has_width") ? "disabled_column" : ""}`} title={field.width}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].width`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].width`}
                              control={control}
                              defaultValue={field.width}
                              render={({ field }) => <input style={{ width: "60px" }} {...field} />}
                            />
                          )}
                        </td>

                        {/* Height */}
                        <td id="6" className={`text-red ${!watch("has_height") ? "disabled_column" : ""}`} title={field.height}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].height`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].height`}
                              control={control}
                              defaultValue={field.height}
                              render={({ field }) => <input style={{ width: "60px" }} {...field} />}
                            />
                          )}
                        </td>

                        {/* Hinges */}
                        <td id="16" className={`text-red ${!watch("has_hinges") ? "disabled_column" : ""}`} title={field.hinges}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].hinges`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].hinges`}
                              control={control}
                              defaultValue={field.hinges || ''}
                              render={({ field }) => (
                                <select style={{ width: "70px" }} {...field}>
                                  {workOrderChoices?.hinges?.map((value, idx) => (
                                    <option key={idx} value={value}>{value}</option>
                                  ))}
                                </select>
                              )}
                            />
                          )}
                        </td>

                        {/* Grill Type */}
                        <td id="7" className={`text-red ${!watch("has_grill_type") ? "disabled_column" : ""}`} title={field.grill_type}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].grill_type`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].grill_type`}
                              control={control}
                              defaultValue={field.grill_type || ''}
                              render={({ field }) => (
                                <select style={{ width: "80px" }} {...field}>
                                  {workOrderChoices?.grill_type?.map((value, idx) => (
                                    <option key={idx} value={value}>{value}</option>
                                  ))}
                                </select>
                              )}
                            />
                          )}
                        </td>

                        {/* Grill Shape */}
                        <td id="7" className={`text-red ${!watch("has_grill_shape") ? "disabled_column" : ""}`} title={field.grill_shape}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].grill_shape`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].grill_shape`}
                              control={control}
                              defaultValue={field.grill_shape || ''}
                              render={({ field }) => (
                                <select style={{ width: "80px" }} {...field}>
                                  {workOrderChoices?.grill_shape?.map((value, idx) => (
                                    <option key={idx} value={value}>{value}</option>
                                  ))}
                                </select>
                              )}
                            />
                          )}
                        </td>


                        {/* Divider */}
                        <td id="8" className={`text-red ${!watch("has_divider") ? "disabled_column" : ""}`} title={field.divider}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].divider`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].divider`}
                              control={control}
                              defaultValue={field.divider}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>

                        {/* Panel */}
                        <td id="10" className={`text-red ${!watch("has_panel") ? "disabled_column" : ""}`} title={field.panel}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].panel`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].panel`}
                              control={control}
                              defaultValue={field.panel || ''}
                              render={({ field }) => (
                                <select style={{ width: "80px" }} {...field}>
                                  {workOrderChoices?.panel_type?.map((value, idx) => (
                                    <option key={idx} value={value}>{value}</option>
                                  ))}
                                </select>
                              )}
                            />
                          )}
                        </td>

                        {/* Design */}
                        <td id="11" className={`text-red ${!watch("has_design") ? "disabled_column" : ""}`} title={field.design}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].design`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].design`}
                              control={control}
                              defaultValue={field.design || ''}
                              render={({ field }) => (
                                <select style={{ width: "80px" }} {...field}>
                                  {workOrderChoices?.design_type?.map((value, idx) => (
                                    <option key={idx} value={value}>{value}</option>
                                  ))}
                                </select>
                              )}
                            />
                          )}
                        </td>

                        {/* Arch Design */}
                        <td id="12" className={`text-red ${!watch("has_arch_design") ? "disabled_column" : ""}`} title={field.louvers}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].arch_design`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].arch_design`}
                              control={control}
                              defaultValue={field.arch_design || false}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>

                        {/* Check Design */}
                        {/* <td id="12" className={`text-red ${!watch("has_check_design") ? "disabled_column" : ""}`} title={field.check_design}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].check_design`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].check_design`}
                              control={control}
                              defaultValue={field.check_design || false}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td> */}

                        {/* Louvers */}
                        <td id="12" className={`text-red ${!watch("has_louvers") ? "disabled_column" : ""}`} title={field.louvers}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].louvers`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].louvers`}
                              control={control}
                              defaultValue={field.louvers || false}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>

                        {/* Glass Partition */}
                        <td id="12" className={`text-red ${!watch("has_glass_partition") ? "disabled_column" : ""}`} title={field.louvers}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].glass_partition`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].glass_partition`}
                              control={control}
                              defaultValue={field.glass_partition || false}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>

                        {/* Manchary */}
                        <td id="9" className={`text-red ${!watch("has_manchary") ? "disabled_column" : ""}`} title={field.manchary}>
                          {isPrintView ? (
                            <p>{field.manchary ? "Yes" : ""}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].manchary`}
                              control={control}
                              defaultValue={field.manchary || false}
                              render={({ field }) => <input type="checkbox" checked={field.value} {...field} />}
                            />
                          )}
                        </td>

                        {/* Open Side */}
                        <td id="12" className={`text-red ${!watch("has_open_side") ? "disabled_column" : ""}`} title={field.open_side}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].open_side`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].open_side`}
                              control={control}
                              defaultValue={field.open_side || false}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>

                        {/* Doorsill */}
                        <td id="14" className={`text-red ${!watch("has_doorsill") ? "disabled_column" : ""}`} title={field.doorsill}>
                          {isPrintView ? (
                            <p>{field.doorsill ? "Yes" : ""}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].doorsill`}
                              control={control}
                              defaultValue={field.doorsill || false}
                              render={({ field }) => <input type="checkbox" checked={field.value} {...field} />}
                            />
                          )}
                        </td>

                        {/* Extra columns */}
                        <td id="15" className={`text-red ${!watch("has_column1") ? "disabled_column" : ""}`} title={field.column1}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].column1`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].column1`}
                              control={control}
                              defaultValue={field.column1 || ""}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>


                        {/* Extra columns */}
                        <td id="15" className={`text-red ${!watch("has_column2") ? "disabled_column" : ""}`} title={field.column2}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].column2`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].column2`}
                              control={control}
                              defaultValue={field.column2 || ""}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>


                        {/* Extra columns */}
                        <td id="15" className={`text-red ${!watch("has_column3") ? "disabled_column" : ""}`} title={field.column3}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].column3`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].column3`}
                              control={control}
                              defaultValue={field.column3 || ""}
                              render={({ field }) => (
                                <textarea style={{ width: "75px", height: "64px" }} {...field}></textarea>
                              )}
                            />
                          )}
                        </td>


                        {/* Quantity */}
                        <td id="15" className={`text-red ${!watch("has_quantity") ? "disabled_column" : ""}`} title={field.value}>
                          {isPrintView ? (
                            <p>{watch(`work_order_items[${index}].quantity`)}</p>
                          ) : (
                            <Controller
                              name={`work_order_items[${index}].quantity`}
                              control={control}
                              defaultValue={field.quantity}
                              render={({ field }) => <input type="number" min={0} style={{ width: "43px" }} {...field} onKeyUp={calculateTotalQuantity()} />}
                            />
                          )}
                        </td>

                        {/* Remove Button */}
                        <td id="17" className={`print_hide ${isPrintView ? "d-none" : ""}`}>
                          <button className="btn btn-sm btn-danger" type="button" onClick={() => remove(index)}>
                            X
                          </button>
                        </td>
                      </tr>
                    ))}

                    <tr className="total-quantity">
                      <td colSpan={columnsCount - 2} className="left-td">
                        Total Quantity
                      </td>
                      <td className="right-td">
                        {totalQuantity}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={'20'} className="print_hide">
                        <div className="d-flex justify-content-end">

                          <button className="btn btn-sm btn-primary"
                            type="button"
                            onClick={() => append({
                              channel_size: '---',
                              channel_guage: '---',
                              particulars: '',
                              type: '---',
                              width: '',
                              height: '',
                              grill_type: '---',
                              grill_shape: '---',
                              manchary: false,
                              louvers: '',
                              panel: '---',
                              design: '---',
                              divider: '',
                              open_side: '',
                              doorsill: false,
                              quantity: 0,
                              hinges: '---',
                              stopper: 0,
                              gate_hook: 0,
                              lock: 0,
                              item_cost: 0,
                              item_price: 0,
                              check_design: "",
                              arch_design: "",
                              glass_partition: "",
                              column1: "",
                              column2: "",
                              column3: "",
                            })}
                            style={{ display: 'block', marginBottom: '10px' }}
                          >
                            Add Row
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="notes">
                {isPrintView ? (
                  watch("notes") ?
                    `${watch("notes")}` : ''
                ) : (
                  <textarea
                    {...register('notes')}
                    placeholder="Notes"
                  ></textarea>)}
              </div>

            </div>
            <input className="btn btn-success print_hide" type="submit" value="Submit" />
          </AvForm>
        </div>
      )}
    </>
  )
}

export default Create
